<template>
    <div>
        <v-data-table
            v-if="client"
            :items-per-page="-1"
            :loading="loading"
            :items="client.quotes"
            :headers="headers"
            class="my-4"
            dense
            :mobile-breakpoint="0"
        >
            <template v-slot:[`item.status`]="{ item }">
                <v-btn
                    style="width: 100px;"
                    rounded
                    small
                    elevation="0"
                    :color="getColor(item.status)"
                    class="my-1 text-center btnStatus white--text"
                >
                    {{ getStatus(item.status) }}
                </v-btn>
            </template>
            <template v-slot:[`item.totalCosts`]="{ item }">
                <div v-if="item.totalCosts">
                    {{
                        new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: item.currency,
                            maximumFractionDigits: 0,
                        }).format(item.totalCosts)
                    }}
                    {{ item.currency }}
                </div>
            </template>

            <template v-slot:[`item.mainContact.name`]="{ item }">
                <div v-if="item.mainContact" class="d-flex align-items">
                    <v-avatar
                        color="grey lighten-4"
                        size="36"
                        class="me-2 my-1"
                    >
                        <v-img
                            v-if="item.mainContact.picture"
                            :src="item.mainContact.profilePicURL"
                        />
                        <span v-else class="text-h6">
                            {{
                                item.mainContact.name
                                    .split(' ')
                                    .map((n, i, a) =>
                                        i === 0 || i + 1 === a.length
                                            ? n[0]
                                            : null
                                    )
                                    .join('')
                                    .toUpperCase()
                            }}
                        </span>
                    </v-avatar>
                    <div
                        v-if="item.mainContact"
                        class="d-flex flex-column align-start align-self-center"
                    >
                        <span class="font-weight-bold text-singleline">
                            {{ item.mainContact.name }}
                        </span>

                        <span class="text-caption text-singleline">
                            {{ item.mainContact.role }}
                        </span>
                    </div>
                </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <a @click="routeQuote(item)">{{ item.name }}</a>
            </template>
            <template v-slot:[`item.number`]="{ item }">
                <div>{{ item.number }}</div>
            </template>
        </v-data-table>
        <!--QUOTE-->
        <v-dialog
            :retain-focus="false"
            v-model="dialogQuote"
            fullscreen
            ref="dialogQuote"
        >
            <v-card v-if="dialogQuote" class="fill-height">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeQuote">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Quote
                </v-card-title>
                <v-card-text
                    class="px-0"
                    :style="`height:${this.heightPopUp}px`"
                >
                    <Quote
                        :id="quote.id"
                        :quote="quote"
                        :settings="settings"
                        :history="!!quote.archive"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'
import moment from 'moment'
export default {
    name: 'ClientProjects',
    props: {
        id: String,
        client: Object,
    },
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
    },
    data() {
        return {
            quote: {},
            heightPopUp: 0,
            dialogQuote: false,
            loadingError: false,
            errorMsg: null,
            loading: false,
            company: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            headers: [
                {
                    text: 'ID',
                    value: 'number',
                    class: 'grey lighten-3',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'NAME',
                    value: 'name',
                    class: 'grey lighten-3',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'MAIN CONTACT',
                    value: 'mainContact.name',
                    class: 'grey lighten-3 ',
                    align: 'left',
                    sortable: false,
                },

                {
                    text: 'TOTAL',
                    value: 'totalCosts',
                    class: 'grey lighten-3 ',
                    align: 'right',
                },
                {
                    text: 'STATUS',
                    value: 'status',
                    class: 'grey lighten-3',
                    align: 'center',
                    sortable: false,
                },
            ],
        }
    },
    watch: {},
    computed: {
        ...mapState(['quotes', 'settings', 'users', 'costQuotes']),
    },
    mounted() {
        this.heightPopUp = window.innerHeight - 62
    },
    methods: {
        ...mapActions([
            'addQuotesAction',
            'addSettingsAction',
            'addUsersAction',
            'addCostAction',
            'addHistoryCostAction',
        ]),
        ...mapMutations(['setClientQuote', 'setErrorItems']),
        closeQuote() {
            this.dialogQuote = false
            this.quote = {}
        },
        routeQuote: async function(item) {
            try {
                this.loading = true
                for (const quote of this.client.quotes) {
                    if (quote.id == item.id) {
                        if (this.costQuotes.length > 0) {
                            const costQuote = this.costQuotes.find(
                                x => x.idQuote == quote.id
                            )

                            if (costQuote) {
                                quote.costs = costQuote
                            } else {
                                quote.costs = await this.getCosts(quote.id)
                            }
                        } else {
                            quote.costs = await this.getCosts(quote.id)
                        }
                        this.quote = quote
                    }
                }
                if (this.quote.archive) {
                    this.quote.attached = this.quote.files.filter(
                        x => x.attach == true
                    )
                    this.quote.docs = this.quote.files.filter(
                        x => x.attach == false
                    )
                    this.quote.sequence =
                        this.quote.createdOn._seconds ||
                        this.quote.createdOn.seconds
                    if (!this.quote.number) {
                        this.quote.shortId = (
                            this.quote.createdOn._seconds ||
                            this.quote.createdOn.seconds
                        )
                            .toString(16)
                            .toUpperCase()
                    }
                }
                this.dialogQuote = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getStatus(status) {
            if (status == 'OPEN') return 'Bidding'
            else if (status == 'AWARDED') return 'Awarded'
            else if (status == 'LOST') return 'Lost'
            else if (status == 'SENT') return 'Follow Up'
            else return 'Approval'
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'AWARDED') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'orange'
        },
        async getCosts(id) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { costs },
                } = await API.getCosts(id)

                if (costs.length > 0) {
                    const index = costs.findIndex(c => !c.alternative)
                    costs[index].idQuote = id
                    this.addCostAction(costs[index])
                }
                const costQuote = this.costQuotes.find(x => x.idQuote == id)
                return costQuote
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },

        getUsers: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.company)
                for (const user of users) {
                    if (user.picture) {
                        const path = `${this.company}/${this.folderUsersPictures}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(user.picture)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                user.profilePicURL = url
                            })
                            .catch(() => {
                                user.profilePicURL = ''
                            })
                    }
                    user.createdOn = moment
                        .unix(user.createdOn._seconds)
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.addUsersAction(users)
                this.client.quotes.forEach(quote => {
                    quote.mainContact = this.users.find(
                        user => quote.userId == user.id
                    )
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        getQuotes: async function() {
            try {
                this.loading = true
                let {
                    data: { quotes },
                } = await API.getQuotes(undefined, undefined, this.client.id)
                for (const quote of quotes) {
                    if (!quote.number) {
                        quote.shortId = quote.createdOn._seconds
                            .toString(16)
                            .toUpperCase()
                    }
                    quote.date = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM DD YYYY ')
                    quote.createdOn = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')

                    if (quote.updatedOn) {
                        quote.updatedOn = moment
                            .unix(quote.updatedOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    }
                }
                this.addQuotesAction(quotes)
                this.client.quotes = quotes
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        try {
            await this.getQuotes()
            if (this.users.length <= 0) {
                this.getUsers()
            } else {
                this.client.quotes.forEach(quote => {
                    quote.mainContact = this.users.find(
                        user => quote.userId == user.id
                    )
                })
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
}
</script>

<style scoped>
.btnStatus {
    cursor: default;
}
a {
    text-decoration: none;
}
.text-singleline {
    line-height: 0.75rem;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:hover {
    background: unset !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
</style>
